















































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'

import PageHeader from '@/components/PageHeader.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ServiceAlertListItem from '@/components/service-alerts/ServiceAlertListItem.vue'
import ServiceAlert from '@/models/ServiceAlert'
import Card from '@/components/Card.vue'
import CardToggleHeader from '@/components/CardToggleHeader.vue'

@Component({
  components: { PageHeader, LoadingSpinner, ServiceAlertListItem, Card, CardToggleHeader },
  computed: {
    ...mapState('alerts', ['resolved', 'unresolved']),
    ...mapGetters('asyncStatus', ['isLoading']),
  },
})
export default class ServiceAlertsView extends Vue {
  unresolved!: ServiceAlert[]
  resolved!: ServiceAlert[]
  isLoading!: (key: string) => boolean

  // We mark the users as having "viewed" their alerts when they visit this page
  // To make sure there was enough time to read them, we have a timer that starts after the alerts are shown on the screen
  // If the user navigates away before the alerts are rendered or the timer fires, we don't mark them as viewed

  timeToView = 2000
  timerId = 0
  wasDestroyed = false

  async created() {
    await this.$store.dispatch('alerts/ensureLoaded')
    if (this.wasDestroyed) return // user navigated away before the alerts loaded
    this.timerId = window.setTimeout(this.viewUnresolved, this.timeToView)
  }

  destroyed() {
    this.wasDestroyed = true
    if (this.timerId) {
      window.clearTimeout(this.timerId)
      this.timerId = 0
    }
  }

  viewUnresolved() {
    const allIds = this.unresolved.map(a => a.id)
    this.$store.dispatch('alerts/viewMany', allIds)
  }

  viewResolved() {
    const allIds = this.resolved.map(a => a.id)
    this.$store.dispatch('alerts/viewMany', allIds)
  }

  onExpand(expanded: boolean) {
    if (expanded) {
      this.viewResolved()
    }
  }

  get hasAlerts() {
    return this.resolved.length || this.unresolved.length
  }
}
