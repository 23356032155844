import { render, staticRenderFns } from "./ServiceAlertsView.vue?vue&type=template&id=35bfbdf6&"
import script from "./ServiceAlertsView.vue?vue&type=script&lang=ts&"
export * from "./ServiceAlertsView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BBadge } from 'bootstrap-vue'
import { BCardHeader } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
import { BCollapse } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
installComponents(component, {BBadge,BCardHeader,BCardTitle,BCollapse,BListGroup})
